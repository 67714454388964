import React from 'react';
import {Helmet} from 'react-helmet'

type PageProps  = {
    title?: string
    content?: string
    children:  React.ReactNode
}

function Page({
    title = 'Best Skincare Products for Glowing Skin Dolce Aura - Home',
    content ="Discover our collection of high-quality skincare products designed to give you a glowing and radiant complexion. Explore our range of cleansers, serums, and moisturizers.",
    children
}: PageProps) {
  
  return (
      <div className="flex w-full h-full flex-col bg-[#FFBD59]">
      <Helmet>
          <title>{title}</title>
          <meta
            name="description"
            content={content}
          />
        </Helmet>
        {children}
    </div>
  );
}

export default Page;
