import React, { ChangeEventHandler, useEffect, useMemo, useState } from "react";
import Pager from "../../componenets/atom/Page";
import { PDFGenerator, PDF_DATA } from "./PDFGenerator";
import { Document, Page, Text, View, StyleSheet, PDFViewer, PDFDownloadLink, Image, Font, BlobProvider, usePDF } from '@react-pdf/renderer';
import RobotoBold from "../../assets/fonts/Roboto-Bold.ttf";
import RobotoRegular from '../../assets/fonts/Roboto-Regular.ttf';
import { Header } from "../../componenets/template/HomeScreen/Header";
import { FieldArray, Formik } from "formik";
import { User, UserDefaultValues } from "../../helper/shared/api/src/type";
import { RootState } from "../../helper/shared/store";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { DatePicker } from "antd";
import dayjs from 'dayjs';
import type { RangePickerProps } from 'antd/es/date-picker';
import moment from "moment";

export const PDGGenerator = () => {
    
    const [showPDFButton , setShowPDFButton ] = useState(false)
    // const [dietStartDate , setDietStartDate ] = useState(dayjs().add(1, 'days'))
    const { id } = useParams();
    const users: User[] = useSelector((root: RootState) => root.userReducer.user) || []
    const user = useMemo(() => {
      const index = users.findIndex((item: User) => item.id === id)
      return index !== -1 ?  users[index]  : null
    }, [users])
    const userDetails = user ? { ...user} : { ...UserDefaultValues }
    const [data , setData] = useState<PDF_DATA>({ 
      name: userDetails?.name || '', 
      weight: userDetails.recentWeight || '', 
      allergy : userDetails.allergies || '', 
      medical: userDetails.medical || '', 
      habits: userDetails.veg || '', 
      dislikes: userDetails.dislike || '', 
      dietStartDate: dayjs().add(1, 'days').format('MMM Do YY'),
      diet: []})

    useEffect(()=> {
        Font.register({
            family: 'Roboto',
            fonts: [
              {
                src: RobotoRegular,
              },
              {
                src: RobotoBold,
                fontWeight: 'bold',
              },
            ],
          });
    })

    
    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
      return current && current < dayjs().endOf('day');
    };

    const FormElement = ({title, placeholder, id, type='text', value, handleChange, error = false, handleBlue}: {error?: Boolean, handleChange: ChangeEventHandler<HTMLInputElement>, handleBlue: ChangeEventHandler<HTMLInputElement> ,value: string,title: string, placeholder: string, id: string, type?: string}) => {
        return <div className="mt-6 md:mt-6">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">{title}</label>
                <input 
                    className={`appearance-none block w-full bg-gray-200 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white ${error ? 'border-red-500' : ''}`}
                    id={id} 
                    type={type} 
                    value={value || ''}
                    onChange={handleChange}
                    onBlur={handleBlue}
                    placeholder={placeholder}>
                </input>
            </div>
    }

    const PatientDetailForm = () => {
        return <Formik
        enableReinitialize={false}
       initialValues={data}
       validate={values => {
        setShowPDFButton(false)
         const errors = {};
         if (!values.name) {
             // @ts-ignore
           errors['name'] = 'Required';
         } 
         if (!values.weight) {
             // @ts-ignore
            errors['weight'] = 'Required';
          }
         return errors;
       }}
       validateOnChange={true}
       onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false)
        setData(values)
        setShowPDFButton(true)
        // update(<PDFGenerator data={data} title={data.name}/>)
      }}
     >
       {({
         values,
         errors,
         touched,
         handleChange,
         handleBlur,
         handleSubmit,
         isSubmitting,
         setFieldValue
         /* and other goodies */
       }) => (
        <form>
        <div className="rounded-md border-2 border-solid p-5 md:p-6 relative">
        <div className="block uppercase absolute top-[-15px] p-1 left-5 bg-white">Patient Details</div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-0 md:gap-5">
            <FormElement title="Name" placeholder="Enter Patient Name" id={'name'} value={values.name} handleChange={handleChange} error={!!(errors.name && touched.name)} handleBlue={handleBlur}/>
            <FormElement title="Weight" placeholder="Enter Weight" id={'weight'} value={values.weight} handleChange={handleChange} handleBlue={handleBlur} error={!!(errors.weight && touched.weight)}/>
            <FormElement title="Allergy" placeholder="Enter Allergy if Any" id={'allergy'} value={values.allergy} handleChange={handleChange} handleBlue={handleBlur} error={!!(errors.allergy && touched.allergy)}/>
            <FormElement title="Medical condition" placeholder="Enter Medical condition if Any" id={'medical'} value={values.medical} handleChange={handleChange} handleBlue={handleBlur} error={!!(errors.medical && touched.medical)}/>
            <FormElement title="Food habits" placeholder="Enter Food habits" id={'habits'} value={values.habits} handleChange={handleChange} handleBlue={handleBlur} error={!!(errors.habits && touched.habits)}/>
            <FormElement title="Dislikes" placeholder="Enter Dislikes " id={'dislikes'} value={values.dislikes} handleChange={handleChange} handleBlue={handleBlur} error={!!(errors.dislikes && touched.dislikes)}/>
        </div>
        </div>
        {
            values.diet.length > 0 ? 
            <div className="grid grid-cols-2">
              <div className="block uppercase my-10">Diet Plan</div>
              <div className="h-10 block uppercase my-10">
                <DatePicker
                    format="DD-MM-YYYY"
                    value={dayjs(values.dietStartDate, 'MMM Do YY')}
                    disabledDate={disabledDate}
                    onChange={(date, dateString)=> {
                      if (date) {
                        setFieldValue('dietStartDate', date.format('MMM Do YY'))
                      }
                    }}
                  />
                </div>
            </div>
            : null
        }
        <FieldArray name="diet">
        {({ insert, remove, push }) => (
            <>
            {values.diet.length > 0 &&
                values.diet.map((diet, index) =>
             <div className="rounded-md border-2 border-solid p-5 md:p-6 relative mt-10" key={`diet.[${index}]`}>
            <div className="block uppercase absolute top-[-15px] p-1 left-5 bg-white">{`${dayjs(values.dietStartDate, 'MMM Do YY').add(index, 'days').format("MMM Do YY")}`}</div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-0 md:gap-5">
                    <FieldArray name={`diet[${index}].dietTime`}>
                      {({ insert, remove, push }) => (
                        <>
                        {diet?.dietTime?.length > 0 &&  diet?.dietTime?.map((item, innerIndex) => {
                          
                          return <>
                          <FormElement key={`diet.[${index}].dietTime.[${innerIndex}].name`} title={`Enter Meal name and Time eg (BreakFast 5 Am)`} placeholder={`Enter Meal name and Time eg (BreakFast 5 Am)`} id={`diet[${index}].dietTime.[${innerIndex}].name`} value={item.name } handleChange={handleChange} handleBlue={handleBlur}/> 
                          <FormElement key={`diet.[${index}].dietTime.[${innerIndex}].value`} title={`Enter ${item.name ? item.name +' Meal' : 'Meal'}`} placeholder={`Enter ${item.name ? item.name : 'Meal'}`} id={`diet[${index}].dietTime.[${innerIndex}].value`} value={item.value} handleChange={handleChange} handleBlue={handleBlur}/> 
                          </>
                          })
                        }
                          <button
                            type="button"
                            className=" absolute right-6 sm:right-16 rounded-md bg-red-600 text-white p-2"
                            onClick={() => {
                              if ( index > 0 && values.diet[0].dietTime.length > values.diet[index].dietTime.length) {
                                push({name:values.diet[0].dietTime[values.diet[index].dietTime.length ].name, value: ''})
                              } else {
                                push({name:'', value: ''})
                              }
                            }}
                          >
                            Add Meal
                          </button>
                          </>
                      )}      
                      </FieldArray>
                    </div>
                    </div>
                )}
                <button
                  type="button"
                  className="mt-4 absolute right-6 sm:right-16 rounded-md bg-red-600 text-white p-2"
                  onClick={() => {
                    if (values.diet.length > 0) {
                    let dietDays: {name: string, value: string}[] = values.diet[0].dietTime 
                    dietDays = dietDays.map(item => {
                      return {name : item.name, value: ''}
                    })
                    push({day: 'firstDay', dietTime:[...dietDays]})
                  } else {
                    push({day: 'firstDay', dietTime:[{name: '', value: ''}]})
                  }
                  }}
                >
                  {values.diet.length > 0 ? 'Add Next Day' : 'Create Diet Plan'}
                </button>
                    </>
        )}
        </FieldArray>
        <button type="button" className=" mt-20 sm:mt-10 sm:w-80 w-full p-3 rounded-md bg-green-600 text-white" disabled={isSubmitting} onClick={()=> handleSubmit()}>
             Submit
           </button>
    </form>
       )}
     </Formik>
    }

    return <Page>
        <Header showNavigation={false}/>
        
        <div className="p-5 md:p-16 ">
        <PatientDetailForm/>
        {showPDFButton ? 
        <BlobProvider document={<PDFGenerator data={data} title={data.name} />}>
                {({ url, blob }) => {
                        return (
                            <a href={url || ''} target="_blank"  className="block mt-[20px] sm:w-80 w-full text-center  rounded-md  bg-blue-600 text-white p-3">
                            View as PDF
                            </a>
                        );
                }}
            </BlobProvider>
        : null}
        </div>
        {/* </div> */}
    </Page> 
}


