import { combineReducers, configureStore } from '@reduxjs/toolkit'
import UserReducer from './UserReducer'
import { persistReducer, persistStore } from 'redux-persist';
import AsyncStorage from '@react-native-async-storage/async-storage';

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  whitelist: [ 'userReducer'],

}
const reducers = combineReducers({
    userReducer: UserReducer   ,
 });

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export const AppDispatch =  store.dispatch
export const persistor = persistStore(store)
