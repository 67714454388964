import React, { useEffect } from "react";
import Page from "../../componenets/atom/Page";
import { useSelector } from "react-redux";
import AOS from 'aos';
import { Header } from "../../componenets/template/HomeScreen/Header";
import { Space, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { deleteUserFromDatabase, fetchAllUser } from "../../helper/shared/api/src";
import { RootState } from "../../helper/shared/store";
import { User } from "../../helper/shared/api/src/type";
import { Modal } from "antd";

export const AllUsers = () => {
    
    useEffect(() => {
        AOS.init();
        fetchAllUser((data: any)=> {
            console.log(data)
        })
      }, [])


      const columns: ColumnsType<User> = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          fixed: 'left',
        },
        {
          title: 'Age',
          dataIndex: 'age',
          key: 'age',
        },
        {
            title: 'Gender',
            key: 'gender',
            dataIndex: 'gender',
          },
        {
            title: 'Height',
            dataIndex: 'height',
            key: 'height',
          },
        {
            title: 'Weight',
            dataIndex: 'recentWeight',
            key: 'recentWeight',
          },
          {
            title: 'Allergies',
            key: 'allergies',
            dataIndex: 'allergies',
          },
        {
          title: 'Action',
          key: 'action',
          fixed: 'right',
          render: (_, record) => (
            <Space size="middle">
              <a href={`/xkode/generate-pdf/${record.id}`}>Add Diet</a>
              <a href={`/xkode/edit/${record.id}`}> Edit</a>
              <a onClick={()=> {
                  if (record?.id) {
                    Modal.confirm({ 
                        title: "Are you sure you want to delete this", 
                        okText: 'Delete',
                        okButtonProps: {type: 'default'},
                        onOk: () => { 
                            deleteUserFromDatabase(record?.id || '', ()=> {
                                fetchAllUser((data: any)=> {
                                    console.log(data)
                                })
                              })
                        }, 
                        }); 
                }
              }}>Delete</a>
              
            </Space>
          ),
        },
      ];
      
      const data: User[] = useSelector((state: RootState) => state.userReducer.user) || []

    return <Page>
        <Header showNavigation={false}/>
      <div className="bg-white">
        <Table columns={columns} dataSource={data} 
             scroll={{ x: 'calc(700px + 50%)'}} />
      </div>
    </Page>
}
