
import { updateUser, clearUserData } from "../../../../store/UserReducer"
import { addUser, editNewUser, fetchAllUsers,  deleteUser} from "../../controller/User"
import firebase from 'firebase/compat/app';
import { PressEvent } from "../../lib/PressEvent"
import { AppDispatch } from "../../../../store";
import { User } from "../../type";

export const fetchAllUser = (onComplete: PressEvent) => {
    fetchAllUsers().then((res: firebase.firestore.QuerySnapshot) => {
        const users = res.docs.map(item => {
            const data = item.data()
            const nameArray:[string] = data.name.split(' ') || []
            const name = nameArray.map(innerItem => innerItem.charAt(0).toUpperCase() + innerItem.slice(1)).join(' ')
            return {
                ...data,
                id: item.id,
                name
            }
        })
        AppDispatch(updateUser(users))
        onComplete(users)
    })
}

export const addNewUser = (data: {}, onComplete: PressEvent) => {
    addUser(data).then(() => {
        onComplete(true)
    })
    .catch(error => {
        console.log(error, 'error')
        onComplete(false)
    })
}

export const editUser = (userDetail: string, data: {}, onComplete: PressEvent) => {
    editNewUser(userDetail, data).then(() => {
        onComplete()
    })
    .catch(error => {
        console.log(error, 'error')
        onComplete(false)
    })
}

export const deleteUserFromDatabase = (userID: string, onComplete: PressEvent) => {
    deleteUser(userID).then(() => {
        onComplete()
    })
}

