
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCSfbJueWwLJ9ptbtNE34vXMcXyjUvcTMI",
    authDomain: "missionfitness-9b7d3.firebaseapp.com",
    databaseURL: "https://missionfitness-9b7d3.firebaseio.com",
    projectId: "missionfitness-9b7d3",
    storageBucket: "missionfitness-9b7d3.appspot.com",
    messagingSenderId: "157936070856",
    appId: "1:157936070856:web:989bad801bc51f355df5fd",
    measurementId: "G-05Q7X76T5Q"
  };
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);
      firebase.firestore().enablePersistence()
      
export const db = firebase.firestore();





