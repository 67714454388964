// import { FirebaseAuthTypes } from "@react-native-firebase/auth";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../api/src/type";

export interface UserState  {
    user?: User[],
}

const initialState: UserState = {
    user: undefined,
}

export const UserReducerSlice = createSlice({
    name: 'userReducer',
    initialState,
    reducers: {
        updateUser: (state: UserState, action: PayloadAction<User[]>) => {
            state.user = action.payload
        },
        clearUserData: (state: UserState) => {
            state.user = undefined
        }
        

    }

})

export const {updateUser, clearUserData} = UserReducerSlice.actions
export default UserReducerSlice.reducer