import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import {Helmet} from 'react-helmet'
import { Navigator } from './Navigator';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { persistor, store } from './helper/shared/store';
import { PersistGate } from 'redux-persist/integration/react';


function App() {
  

  return <Provider store={store}>
  <PersistGate loading={null} persistor={persistor}>
    <Navigator/>
    </PersistGate>
    </Provider>
}

export default App;
