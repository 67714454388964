import React from "react";
import { useNavigate } from "react-router-dom";
import { DietLogo } from "../../../../assets/Images";
import {ReactComponent as Logo} from "../../../../assets/Images/Logo.svg";

export const Header = ({showNavigation = true}: {showNavigation?: Boolean}) => {
    const navigate = useNavigate();

    const navigateToHome = () => {
            navigate("/");
    }

    const navigateToProducts = () => {
        navigate("/product");
}

    return <>
    {showNavigation ?
    <section className="flex items-center justify-center h-12 ">
            <p className="text-center text-sm">LAUNCHING SOON WITH PREMIUM OFFERS</p>
        </section>
: null}
        <section className="flex flex-col items-center justify-around bg-white h-[250px] sm:h-[250px] relative">
            <img src={DietLogo} className="w-3/5 sm:w-1/6 aspect-square object-contain"/>
            {showNavigation ? 
            <div className="grid grid-cols-2 sm:grid-cols-4 lg:gap-10 mb-10">
                <a className="item hover:underline hover:scale-125 duration-200" >About us</a>
                <a className="item hover:underline hover:scale-125 duration-200" >Contact us</a>
            </div>
            : <div className="bg-green-600 h-[10px] absolute bottom-0 right-0 left-0"/>
            }
            
        </section>
        
    </>
}