import React, { useEffect, useState } from "react";
import { Document, Page, Text, View, StyleSheet, PDFViewer, PDFDownloadLink, Image, Font } from '@react-pdf/renderer';
import dayjs, { Dayjs } from 'dayjs';

export type PDF_DATA = {dietStartDate: string; name: string; weight: string; allergy: string; medical: string; habits: string; dislikes: string; diet: {day: string, dietTime: {name: string, value: string}[]}[] }

export const PDFGenerator = ({title, data}: {title: string, data: PDF_DATA})  => {

const styles = StyleSheet.create({
    page: {
      padding: 25
    },
    image: {
		width: '30%',
        objectFit: 'cover',
        aspectRatio: 1,
	},
    document: {
        flex: 1,
        width: '100%',
        height: '100vh'
    },
    section: {
        paddingLeft: 10,
        paddingTop: 30,
    },
    sectionDay: {
        paddingLeft: 10,
        paddingTop: 30,
        marginTop: 40,
    },
    divider: {
        height: '5px',
        width: '100%',
        marginTop: 4,
        marginBottom: 4,
        backgroundColor: 'green'
    },
    text: {
        fontSize: 12,
        marginTop: 6,
        fontFamily: 'Roboto', 
    },
    highlightText: {
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: 12,
        marginTop: 6,
        color: 'green'
    },
    heading: {
        fontSize: 12,
        fontFamily: 'Roboto', 
        fontWeight: 'bold',
        paddingBottom: 5,
    }
  });

    return  <Document title={title}> 
        <Page size="A4" style={styles.page} >
        <Image style={styles.image} src={require("../../assets/Images/dietLogo.png")} />
        <View style={styles.divider}/>
        <View style={styles.section}>
            <Text style={styles.heading}>Patient Profile</Text>
            <Text style={styles.text}>{`Patient Name:${data.name}`}</Text>
            <Text style={styles.text}>{`Weight – ${data.weight}`}</Text>
            <Text style={styles.text}>{`Allergy – ${data.allergy}`}</Text>
            <Text style={styles.text}>{`Medical condition- ${data.allergy}`}</Text>
            <Text style={styles.text}>{`Food habits- ${data.habits}`}</Text>
            <Text style={styles.text}>{`Dislikes: ${data.dislikes}`}</Text>
        </View>
        <View style={styles.section}>
            <Text style={styles.heading}>NOTE:</Text>
            <Text style={styles.text}>This diet plan is customized and follows personal details of the client. Do not share this plan with anyone else, concerned dietician is not responsible.</Text>
        </View>
        {/* <View style={styles.section}>
            <Text style={styles.heading}>Meal Timings [these are the ideal timings adjust according to you but have dinner before 8:30pm]</Text>
            <Text style={styles.text}>Early morning – 4am-6am</Text>
            <Text style={styles.text}>Breakfast- 8am-10am</Text>
            <Text style={styles.text}>Midmorning- 11am-12pm Lunch-1pm-2pm</Text>
            <Text style={styles.text}>Evening snacks- 4-5:30pm Dinner-6:30-9:30</Text>
            <Text style={styles.text}>Sleep- Max by 11pm</Text>
        </View> */}
        <View style={styles.section}>
            <Text style={styles.heading}>This is the portion size guide. In this it is written what is 1 bowl, 1 spoon. So, that you can realize about your portion size.</Text>
            <Text style={styles.text}>Glass-250 ml</Text>
            <Text style={styles.text}>Small bowl-50 grams Cup-100 ml</Text>
            <Text style={styles.text}>Small plate-100 gram Big plate-120 gram Tbsp-15 gram</Text>
            <Text style={styles.text}>Tsp-5 gram</Text>
        </View>
        {
            data.diet.map((item, index) => {
                return <View style={styles.sectionDay}>
                    <Text style={styles.heading}>{`${dayjs(data.dietStartDate, 'MMM Do YY').add(index, 'days').format("MMM Do YY")}`}</Text>
                    {
                        item.dietTime.map((innerItem, innerIndex) => {
                            return <Text style={styles.text}>{`${innerItem.name} - ${innerItem.value}`}</Text>
                        })  
                    }
                </View>
            })
        }
        <View style={styles.section}>
            <Text style={styles.heading}>**Guideliness:**</Text>
            <Text style={styles.text}>1. Eat frequent and small meals.</Text>
            <Text style={styles.text}>2. Drink at least 2-3 liters of water daily.</Text>
            <Text style={styles.text}>3. Get a minimum of 7 hours of sleep each night.</Text>
            <Text style={styles.text}>4. Engage in physical activities like walking, jogging, dancing, or yoga for at least 40 minutes on 5 days of the week.</Text>
            <Text style={styles.text}>5. Keep your sugar intake to a maximum of 2 teaspoons a day.</Text>
            <Text style={styles.text}>6. Manage your stress and practice deep breathing at any time during the day.</Text>
        </View>
            <View style={styles.section}>
            <Text style={styles.heading}>OIL USAGE GUIDE</Text>
            <Text style={styles.text}>1. Use desi ghee for making roti and dal.</Text>
            <Text style={styles.text}>2. Use peanut, mustard, sunflower, or coconut oil for Indian curries and soups. If you have acidity issues, avoid using mustard oil.</Text>
            {/* <Text style={styles.text}>3. Use rice bran, peanut, or olive oil for making parathas and idlis.</Text> */}
        </View>

        <View style={styles.section}>
            <Text style={styles.heading}>Lifestyle Changes for Weight Loss and Healthy life.</Text>
            <Text style={styles.text}>1. Get 7-8 hours of sleep every night. Try to go to bed before 11 PM and wake up between 6-7 AM.</Text>
            <Text style={styles.text}>2. Stay active physically. Exercise daily or take a walk or play your favorite sport.</Text>
            <Text style={styles.text}>3. Avoid eating processed foods. Say no to anything that comes in a packet.</Text>
            <Text style={styles.text}>4. Spend at least 20 minutes in the morning sunlight.</Text>
            <Text style={styles.text}>5. Meditate for at least 10 minutes at any time during the day to keep your mind healthy.</Text>
        </View>

        <View style={styles.section}>
            <Text style={styles.highlightText}>For any queries, feel free to contact at: +918968145734</Text>
        </View>

        <View style={styles.section}>
            <Text style={styles.heading}>DT. PRAGYA JAIN</Text>
        </View>

    </Page>
    </Document>
}