
import {db} from '../../../../../Database';

export const addUser = ( userDetails: {}) => {
    return db.collection('user').add(userDetails)
}

export const editNewUser = (userID:string, userDetails: {}) => {
    return db.collection('user').doc(userID).set(userDetails)
}

export const fetchAllUsers = () => {
    return db.collection('user').get()
}

export const deleteUser = (userID:string) => {
    return db.collection('user').doc(userID).delete()
}

export const name = {}