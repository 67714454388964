import React, { ChangeEventHandler, useEffect, useMemo, useState } from "react";
import Page from "../../componenets/atom/Page";
import { Header } from "../../componenets/template/HomeScreen/Header";
import { Button, Result, Spin } from 'antd';
import { FieldArray, Formik } from "formik";
import { AddUserKeys, User, UserDefaultValues, userSchemaValidation } from "../../helper/shared/api/src/type";
import { addNewUser, editUser } from "../../helper/shared/api/src";
import { useLocation, useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "../../helper/shared/store";


export const AddUser = () => {
    
    const [added, setAdded ] = useState(false)
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const users: User[] = useSelector((root: RootState) => root.userReducer.user) || []
    const user = useMemo(() => {
      const index = users.findIndex((item: User) => item.id === id)
      return index !== -1 ?  users[index]  : null
    }, [users])
    const userDetails = user ? { ...user} : { ...UserDefaultValues }
    const [data , setData] = useState(userDetails)

    if (location.pathname.includes('edit') && user === null) return null

    const FormElement = ({title, placeholder, id, type='text', value, handleChange, error = false, handleBlue}: {error?: Boolean, handleChange: ChangeEventHandler<HTMLInputElement>, handleBlue: ChangeEventHandler<HTMLInputElement> ,value: string,title: string, placeholder: string, id: string, type?: string}) => {
        return <div className="mt-6 md:mt-6">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">{title}</label>
                <input 
                    className={`appearance-none block w-full bg-gray-200 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white ${error ? 'border-red-500' : ''}`}
                    id={id} 
                    type={type} 
                    value={value || ''}
                    onChange={handleChange}
                    onBlur={handleBlue}
                    placeholder={placeholder}>
                </input>
            </div>
    }

    const PatientDetailForm = () => {
        return <Formik
       initialValues={data}
       validationSchema={userSchemaValidation}
       validateOnChange={true}
       onSubmit={(values, { setSubmitting }) => {
         if (user && user?.id) {
          editUser(user.id,values, (data)=> {
            if (data) {
              setSubmitting(false)
              navigate('/xkode/allusers') 
            } else {
              alert('Something went wrong')
            }
          })
         } else {
          addNewUser(values, (data)=> {
            setSubmitting(false)
            if (data) {
              setAdded(true)
            } else {
              alert('Something went wrong')
            }
          })
         }
      }}
     >
       {({
         values,
         errors,
         touched,
         handleChange,
         handleBlur,
         handleSubmit,
         isSubmitting,
         /* and other goodies */
       }) => (
        <form>
        <div className="rounded-md border-2 border-solid p-5 md:p-6 relative">
        <div className="block uppercase absolute top-[-15px] p-1 left-5 bg-white">Patient Details</div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-0 md:gap-5">
            { AddUserKeys.map((item) => {
              // @ts-ignore
              return <FormElement key={item.key} title={item.lable} placeholder={item.placeholder} id={item.key} value={values[item.key]} handleChange={handleChange} error={!!(errors[item.key] && touched[item.key])} handleBlue={handleBlur}/>
            }) 
          }
        </div>
        </div>
        <button type="button" className='mt-20 sm:mt-10 sm:w-80 w-full p-3 rounded-md bg-green-600 text-white'  onClick={()=> {handleSubmit()}}>
             {isSubmitting ? <Spin size="small" /> : user ? 'Update' : 'Submits'}
           </button>
    </form>
       )}
     </Formik>
    }

    return <Page>
        <Header showNavigation={false}/>
          <div className="p-5 md:p-16 bg-white ">
            {
              added ? 
              <Result
              status="success"
              title="Thank you for submitting details"
              subTitle="Dietician Pragya will review all information and come back to you soon!!"
              
            />
            : <PatientDetailForm/>
          }
          
        </div>
    </Page> 
}
