import { boolean, number, object, string } from "yup";
import * as Yup from 'yup';

export interface User {
    id?: string;
    name?: string;
    age?: string;
    gender ?: string;
    height ?: string;
    recentWeight ?: string;
    dob?: string; 
    address?: string; 
    armCircum?: string;
    legCircum?: string;
    waistCircum?: string;
    hipCircum?: string;
    medical?: string; 
    allergies?: string; 
    dislike?: string; 
    likes?: string; 
    veg?: string;
    alcohol?: string; 
    anyOtherSymptom?: string;
    prostateHealth?: string; 
    stoolFrequency?: string; 
    lessMuscle?: Boolean;
    drySkin?: Boolean;
    oilySkin?: Boolean;
    feelFatiguedSoon ?: Boolean
    feelLethargicInMorning ?: Boolean
    nightSleepHours ?: string;
    anyGastricIssues ?: string
    dailyStepCount?: string; 
    dailyWaterIntake ?: string;
    likeOilyFoods? : boolean
    breakfast?: string;
    lunch?: string;
    evening?: string;
    dinner?: string;
    anyJunkFoodLike ?:  string; 
    anyStressesPresently ?: string
    anyHereditaryAilment?: string
    packageDetails?: string; 
    packagetype?: string;
    
  }

  export const userSchemaValidation = Yup.object().shape({
    name: string().required(),
    age: number().required().positive().integer(),
    gender : string().required(),
    height : string().required(),
    recentWeight : string().required(),
    dob: string().required(),
    address: string().required(),
    armCircum: string().optional(),
    legCircum: string().optional(),
    waistCircum: string().optional(),
    hipCircum: string().optional(),
    medical: string().optional(),
    allergies: string().optional(),
    dislike: string().optional(), 
    likes: string().optional(),
    veg: string().required(),
    alcohol: string().optional(),
    anyOtherSymptom: string().optional(),
    prostateHealth: string().optional(),
    stoolFrequency: string().required(),
    lessMuscle: string().required(),
    drySkin: string().required(),
    oilySkin: string().required(),
    feelFatiguedSoon : string().required(),
    feelLethargicInMorning : string().required(),
    nightSleepHours : string().required(),
    anyGastricIssues : string().optional(),
    dailyStepCount: string().required(),
    dailyWaterIntake : string().required(),
    likeOilyFoods : string().required(),
    breakfast: string().required(),
    lunch: string().required(),
    evening: string().required(),
    dinner: string().required(),
    anyJunkFoodLike :  string().optional(),
    anyStressesPresently : string().required(),
    anyHereditaryAilment: string().required(),
    packageDetails: string().required(),
    packagetype: string().required(),
  });

  export const UserDefaultValues = {
    name : '',
    age : '',
    gender  : '',
    height  : '',
    recentWeight  : '',
    dob : '', 
    address : '', 
    armCircum : '',
    legCircum : '',
    waistCircum : '',
    hipCircum : '',
    medical : '', 
    allergies : '', 
    dislike : '', 
    likes : '', 
    veg : '',
    alcohol : '', 
    anyOtherSymptom : '',
    prostateHealth : '', 
    stoolFrequency : '', 
    lessMuscle : '',
    drySkin : '',
    oilySkin : '',
    feelFatiguedSoon : '',
    feelLethargicInMorning : '',
    nightSleepHours  : '',
    anyGastricIssues : '',
    dailyStepCount : '', 
    dailyWaterIntake  : '',
    likeOilyFoods : '',
    breakfast : '',
    lunch : '',
    evening : '',
    dinner : '',
    anyJunkFoodLike : '',
    anyStressesPresently : '',
    anyHereditaryAilment: '',
    packageDetails : '', 
    packagetype : '',
    
  }

  export const AddUserKeys = [
    {
      key : 'name',
      placeholder : 'Enter Name',
      lable: 'Name *' 
    },
    {
      key : 'age',
      placeholder : 'Enter age',
      lable: 'Age *' 
    },
    {
      key : 'gender',
      placeholder : 'Enter Gender',
      lable: 'Gender *' 
    },
    
    {
      key : 'height',
      placeholder : 'Enter height',
      lable: 'Height *' 
    },
    {
      key : 'recentWeight',
      placeholder : 'Enter Recent Weight',
      lable: 'Recent Weight *' 
    },

    {
      key : 'dob',
      placeholder : 'Enter Recent Weight',
      lable: 'Date Of Birth *' 
    },
    
    {
      "key": "address",
      "placeholder": "Enter address",
      "lable": "Address *"
    },
    {
      "key": "armCircum",
      "placeholder": "Enter arm Circum",
      "lable": "Arm Circum"
    },
    {
      "key": "legCircum",
      "placeholder": "Enter leg Circum",
      "lable": "Leg Circum"
    },
    {
      "key": "waistCircum",
      "placeholder": "Enter Waist Circum",
      "lable": "Waist Circum"
    },
    {
      "key": "hipCircum",
      "placeholder": "Enter Hip Circum",
      "lable": "Hip Circum"
    },
    {
      "key": "medical",
      "placeholder": "Enter medical Issues (if any)",
      "lable": "Medical Issues (if any)"
    },
    {
      "key": "allergies",
      "placeholder": "Enter allergies",
      "lable": "Allergies"
    },
    {
      "key": "dislike",
      "placeholder": "Enter dislike in food",
      "lable": "Dislike in Food"
    },
    {
      "key": "likes",
      "placeholder": "Enter likes",
      "lable": "Likes"
    },
    {
      "key": "veg",
      "placeholder": "Enter Veg/Non-Veg",
      "lable": "Veg/Non-Veg *"
    },
    {
      "key": "alcohol",
      "placeholder": "Enter alcohol",
      "lable": "Alcohol"
    },
    {
      "key": "anyOtherSymptom",
      "placeholder": "Enter Any Other Symptom",
      "lable": "Any Other Symptom"
    },
    {
      "key": "prostateHealth",
      "placeholder": "Enter Prostate Health (For Men only)",
      "lable": "Prostate Health (For Men only)"
    },
    {
      "key": "stoolFrequency",
      "placeholder": "Enter Stool Frequency",
      "lable": "Stool Frequency *"
    },
    {
      "key": "lessMuscle",
      "placeholder": "Enter lessMuscle",
      "lable": "Less Muscle (Yes/NO) *",
    },
    {
      "key": "drySkin",
      "placeholder": "Enter drySkin",
      "lable": "Dry Skin (Yes/NO) *",
    },
    {
      "key": "oilySkin",
      "placeholder": "Enter oilySkin",
      "lable": "Oily Skin (Yes/NO) *",
    },
    {
      "key": "feelFatiguedSoon",
      "placeholder": "Enter Feel FatiguedSoon",
      "lable": "Feel Fatigued Soon (Yes/NO) *",
    },
    {
      "key": "feelLethargicInMorning",
      "placeholder": "Enter Feel Lethargic In Morning",
      "lable": "Feel Lethargic In Morning (Yes/NO) *",
    },
    {
      "key": "nightSleepHours",
      "placeholder": "Enter Night Sleep Hours",
      "lable": "Night Sleep Hours *"
    },
    {
      "key": "anyGastricIssues",
      "placeholder": "Enter any Gastric Issues",
      "lable": "Any Gastric Issues "
    },
    {
      "key": "dailyStepCount",
      "placeholder": "Enter Daily Step Count",
      "lable": "Daily Step Count *"
    },
    {
      "key": "dailyWaterIntake",
      "placeholder": "Enter daily Water Intake",
      "lable": "Daily Water Intake *"
    },
    {
      "key": "likeOilyFoods",
      "placeholder": "Enter like Oily Foods",
      "lable": "Like Oily Foods "
    },
    {
      "key": "breakfast",
      "placeholder": "Enter early morning meal",
      "lable": "Early Morning Meal *"
    },
    {
      "key": "lunch",
      "placeholder": "Enter lunch meal",
      "lable": "Lunch Meal *"
    },
    {
      "key": "evening",
      "placeholder": "Enter evening meal",
      "lable": "Evening Meal *"
    },
    {
      "key": "dinner",
      "placeholder": "Enter dinner meal",
      "lable": "Dinner Meal *"
    },
    {
      "key": "anyJunkFoodLike",
      "placeholder": "Enter Any Junk Food Like",
      "lable": "Any Junk Food Like",
      
    },
    {
      "key": "anyStressesPresently",
      "placeholder": "Enter Any Stresses Presently",
      "lable": "Any Stress Presently (Yes/NO) *",
    },
    {
      "key": "anyHereditaryAilment",
      "placeholder": "Enter Any Hereditary Ailment ",
      "lable": "Any Hereditary Ailment (Yes/NO) *",
    },
    {
      "key": "packageDetails",
      "placeholder": "Enter Package Details",
      "lable": "Package Details *"
    },
    {
      "key": "packagetype",
      "placeholder": "Enter Package type",
      "lable": "(Normal / pregnant/ lactating) *"
    },
    
  ]