import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HomeScreen } from "../pages/home";
import { AllUsers } from "../pages/AllUsers";
import {  AddUser } from "../pages/addUser";
import { PDGGenerator } from "../pages/PDGGenerator";

export const Navigator = () => {
  const today = new Date();
  const minutes = today.getMinutes()

console.log(today.getMinutes(), 'getMinutes')
    return <BrowserRouter>
    <Routes>
      
      <Route path={`/xkode/generate-pdf/:id`} element={<PDGGenerator/>}/>
      <Route path={`/xkode/allUsers`} element={<AllUsers/>}/>
      <Route path={`/register`} element={<AddUser/>}/>
      <Route path={`/xkode/edit/:id`} element={<AddUser/>}/>
        
        {/* <Route path="*" element={<NoPage />} /> */}
    </Routes>
  </BrowserRouter>
}